@import 'bootstrap-variables';
@import '@geneplanet/bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');
// themes - our custom or/and out of the box themes
@import 'themes';
// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';
@import '@nebular/bootstrap/styles/globals';
// loading progress bar theme
@import './pace.theme';
@import './overrides';
@import './variables';
@import './scrollbar';

// install the framework and custom global styles
@include nb-install() {
  // framework global styles
  @include nb-theme-global();
  @include nb-bootstrap-global();

  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
}
:root {
  --two-column-layout-left-column-width: 16rem;
  // --two-column-layout-margin-between: 16px;
  --two-column-layout-margin-between: 0px;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
}

// checkbox
.gpu-checkbox.gpu-checked {
  .gpu-checkmark,
  .gpu-checkmark-outline {
    stroke: $color-primary !important;
  }

  &:hover,
  &:focus,
  &:active {
    .gpu-icon-container {
      box-shadow: 0 0 0 0.5rem rgba($color-primary, 0.1) !important;
      background-color: rgba($color-primary-background, 0.1) !important;
    }
  }
}

// buttons
.nbtn-1 {
  @extend .gbtn;
  background: $color-primary;
  color: $white !important;

  &:hover,
  &:active,
  &.active {
    background: rgba($color-primary, 0.7);
  }

  &.disabled,
  &:disabled {
    color: $gray-4 !important;
    background: $gray-3 !important;
  }
}

.nbtn-2 {
  @extend .gbtn;
  color: $color-primary !important;
  border-color: $color-primary !important;

  &:hover,
  &:active,
  &.active {
    background: rgba($color-primary, 0.3);
  }

  &.disabled,
  &:disabled {
    color: $gray-4 !important;
    background: $gray-3 !important;
    border-color: $gray-3 !important;
  }
}

.icon-btn {
  @extend .nbtn-1;
  padding: 0;
  border-radius: 4px;
}

.icon-btn-clear {
  @extend .nbtn-2;
  padding: 0;
  border-radius: 4px;
  border: unset !important;
}

.icon-btn-lg {
  min-width: 2.5rem;
  height: 2.5rem;
}

.icon-btn-sm {
  min-width: 1.625rem;
  height: 1.625rem;
  font-size: 12px;
}

// select
.nipt-variant {
  &.gpu-form-field {
    --border-radius: 4px;
    --infix-padding: 3px;
    min-width: 150px;
    .gpu-form-field-wrapper {
      &.gpu-focused {
        .gpu-form-field-outline-start,
        .gpu-form-field-outline-gap,
        .gpu-form-field-outline-end {
          border-color: $color-primary;
        }
        .gpu-form-field-label {
          color: $color-primary;
        }

        .gpu-select .gpu-select-icon {
          color: $color-primary;
        }
      }

      .gpu-form-field-flex {
        background: #f7f9fc !important;
        padding-bottom: 1px;
        padding-top: 1px;
      }
    }

    .gpu-select-wrapper {
      display: flex;
      align-items: center !important;

      .gpu-select-value {
        margin-right: auto;
        padding-right: 0;

        span {
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          color: var(--bs-body-color);
        }
      }

      .gpu-select-icon {
        position: relative;
        right: 0;
        top: 0;
        color: var(--bs-body-color);

        i {
          display: block;
        }
      }
    }
  }
}

.gpu-select-options-wrapper {
  border-radius: 4px !important;
  gpu-option {
    .gpu-option-selected {
      background-color: $color-primary !important;
      color: $white !important;
    }
    .gpu-option-wrapper {
      padding: 7px 16px !important;
      font: 'Open Sans', sans-serif !important;
      font-size: 15px !important;
    }
  }
}

.full-width {
  .gpu-form-field-infix {
    width: 100% !important;
  }
}

input[type='text'],
input[type='email'] {
  border-radius: 4px;
  border: 1px solid #cdcdce;
  background-color: #f7f9fc;

  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none;
    border-color: $color-primary;
  }
}

/* START temporary fixes until nebular is removed */
ngb-modal-backdrop,
ngb-modal-window {
  z-index: 1040 !important;
}

.cdk-overlay-container {
  z-index: 1041 !important;
}
/* END temporary fixes until nebular is removed */
