/* You can add global styles to this file, and also import other style files */
@use 'app/modules/@theme/styles/variables';
@use 'app/modules/@theme/styles/loader';
@use 'app/modules/@shared/styles/styles';

html {
  overflow-y: hidden !important;
}
.nb-theme-default nb-sidebar {
  z-index: 1040;
  nb-menu .menu-item .menu-icon {
    min-width: 1em;
  }
}
.nb-theme-default nb-layout .layout nb-layout-header nav {
  background-color: white;
  border-bottom: 1px solid variables.$border-color;
}
.nb-theme-default nb-user .initials {
  color: variables.$color-text-primary;
  border-color: variables.$color-text-primary;
}
.nb-theme-default nb-dialog-container nb-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nb-dialog-container {
  nb-card {
    max-width: 95vw;
    max-height: 95vh;
  }
}

.cursor-pointer {
  cursor: pointer;
}

input[disabled][disabled][disabled] {
  cursor: not-allowed;
  background: variables.$color-disabled-background;
}

.nb-theme-default {
  nb-radio.no.status-basic .native-input:disabled:checked ~ .inner-circle {
    background-color: red;
  }

  nb-radio.status-basic .native-input:disabled {
    ~ .text {
      color: black;
    }
    &:checked ~ .inner-circle {
      background-color: variables.$color-primary;
    }
  }
}

.tableCommandButtons {
  padding-bottom: 10px;
  display: flex;
}

ngb-pagination {
  .pagination {
    .page-item {
      &:first-child {
        .page-link {
          display: none;
        }
      }
      &:last-child {
        .page-link {
          display: none;
        }
      }
    }
  }
}

body ngb-typeahead-window.dropdown-menu {
  z-index: 1041;
}

app-kits-list-page {
  .tableCommandButtons {
    nb-checkbox {
      label {
        padding-right: 0px !important;
      }
    }
  }
}

app-dashboard-page {
  height: 100%;
}

app-documents-waiting-for-approval-list {
  .table-container {
    height: 100%;
  }
}

.nb-theme-default {
  .label-primary {
    color: #00d9dd;
    font-weight: 600;
  }
}

.options-list-container {
  width: auto !important;
  max-width: 70vw !important;
}

.radio-toolbar {
  display: flex;
}
.nb-theme-default nb-context-menu {
  max-width: 100%;
}

.tabletMode{
  .dropdown-menu{
    min-width: 100%;
    .dropdown-item{
      padding: 1rem 1.5rem;
    }
  }
}

.stickyTableHeader {
  app-one-column-layout .content {
    overflow: hidden !important;
    .columns {
      height: 100%;
      .table-holder {
        display: flex;
        flex-direction: column;
        .table-container {
          overflow: auto;
          flex: 1;
          table th {
            position: sticky;
            top: 0;
            z-index: 1;
            background: white;
          }
        }
      }
    }
  }
}
.nb-theme-default [nbButton].size-small nb-icon,
.nb-theme-default .size-small[nbButtonToggle] nb-icon {
  margin-top: 0;
  margin-bottom: 0;
}

.nb-theme-default nb-option-list {
  width: auto !important;
  max-width: 30rem;
}

nb-option.clear_all {
  position: sticky;
  top: 0;
  background: white !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  z-index: 10;
  display: flex;
  align-items: center;
  &.hover {
    text-decoration: underline;
  }
}

.nb-theme-default nb-select.size-tiny .select-button.placeholder {
  font-size: 0.75rem;
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.1);
}

input.status-basic[nbInput][disabled] {
  color: black;
}

.form-group {
  margin-bottom: 1rem;
}

[class*='row-'],
.row {
  --bs-gutter-x: 1.875rem;
}

.placeholder {
  background-color: #f0f0f0;
  opacity: 1 !important;
}

a {
  color: variables.$color-primary;
  text-decoration: underline;
}
